import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { VALIDATIONS } from '@client/shared/constants';
import { validate as validator } from '@client/utils';
import DetailsView from './view';
import Services from './service';
import PaymentServices from '../add-payment-method/service';
import { setUserToken } from '@client/utils';

const defaultState = {
  currentTab: '1',
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    state: '',
  },
  errors: {},
  checkoutError: '',
  isButtonLoading: false,
  isPaymentButtonLoading: false,
  isCheckoutLoading: false,
};
const tabs = [
  { label: '1', title: 'Details' },
  { label: '2', title: 'Payment Method' },
  { label: '3', title: 'Order Summary' },
];
const DetailsModule = ({ isExistingCustomer = false, isUpgrading = false, isNewPlan = false }) => {
  const [state, setState] = useState({
    ...defaultState,
    currentTab: isExistingCustomer ? '2' : isUpgrading || isNewPlan ? '3' : defaultState.currentTab,
  });
  let product = localStorage.getItem('productId');
  if (product) {
    product = JSON.parse(product);
  }
  //Post APi for user signUp
  const handleCreateUser = useCallback(async () => {
    if (state.userDetails.password !== state.userDetails.confirmPassword) {
      return toast.error('Password and Confirm Password must match');
    }
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: true,
    }));
    const { confirmPassword, ...payload } = state.userDetails;
    const { data, error } = await Services.createUser(payload);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isButtonLoading: false,
      }));
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      isButtonLoading: false,
    }));
    setUserToken(data?.user);
    handleTabChange('2');
    return toast.success('User created successfully.');
  });

  //Post api to create subscription based on product chosen
  const handleCreateSubscription = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isPaymentButtonLoading: true,
    }));
    const payload = {
      productId: product.id,
    };
    const { data, error } = await Services.createSubscription(payload, isExistingCustomer);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isPaymentButtonLoading: false,
      }));
      if (error[0].code == '401') {
        return toast.error('Your session has expired. Please try the payment again after logging in.');
      }
      return toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
    }
    setState((prevState) => ({
      ...prevState,
      isPaymentButtonLoading: false,
    }));
    handleTabChange('3');
    return {
      clientSecret: data.subscription?.clientSecret || '',
      subscriptionId: data.subscription?.subscriptionId || '',
    };
  });

  const handleCheckoutError = (value) => {
    setState((prevState) => ({
      ...prevState,
      checkoutError: value,
    }));
  };
  const handleCheckoutLoading = (value) => {
    setState((prevState) => ({
      ...prevState,
      isCheckoutLoading: value,
    }));
  };
  const handleTabChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      currentTab: value,
    }));
  };
  //Post api to attath the payment method to a user
  const handleAttatchPaymentMethod = useCallback(async (id) => {
    const { error } = await PaymentServices.addPaymentMethod({ id }, isExistingCustomer);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].error || error[0].message : error);
      return { error: true };
    }
    return { error: false };
  });

  const handleFieldChange = (evt, type = '') => {
    const { name, value } = type === 'dropdown' ? evt : evt.currentTarget || evt.target;
    let errorMessage = validateFields(name, value) || ' ';
    setState((prevState) => ({
      ...prevState,
      userDetails: {
        ...prevState.userDetails,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: errorMessage,
      },
    }));
  };
  const validateFields = (field, value) => {
    let errorMessage = '';
    const fieldValidatorMap = {
      firstName: [{ type: VALIDATIONS.REQUIRED, value: true }],
      lastName: [{ type: VALIDATIONS.REQUIRED, value: true }],
      email: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.EMAILS, value: '' },
      ],
      password: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.PASSWORD, value: true },
      ],
      confirmPassword: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.PASSWORD, value: true },
      ],
      addressLine1: [{ type: VALIDATIONS.REQUIRED, value: true }],
      zipCode: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 10 },
      ],
      state: [{ type: VALIDATIONS.REQUIRED, value: true }],
    };
    if (fieldValidatorMap[field]) {
      const validationResult = fieldValidatorMap[field].map((validation) =>
        validator(value, validation.type, validation.value, validation.inputType || 'string', validation.message),
      );
      errorMessage = validationResult.filter((error) => !error?.isValid).map((error) => error?.errorMessage)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validateFields(key, state.userDetails[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }
    return errorMessage;
  };

  const isFormValidated = !validateFields() && !state.errors?.confirmPassword?.trim();
  return (
    <DetailsView
      tabs={tabs}
      currentTab={state.currentTab}
      userDetails={state.userDetails}
      errors={state.errors}
      paymentDetails={state.paymentDetails}
      isButtonLoading={state.isButtonLoading}
      isCheckoutLoading={state.isCheckoutLoading}
      isPaymentButtonLoading={state.isPaymentButtonLoading}
      productDetails={product}
      checkoutError={state.checkoutError}
      isFormValidated={isFormValidated}
      isExistingCustomer={isExistingCustomer}
      isUpgrading={isUpgrading}
      isNewPlan={isNewPlan}
      handleTabChange={handleTabChange}
      handleFieldChange={handleFieldChange}
      handleCreateUser={handleCreateUser}
      handleCreateSubscription={handleCreateSubscription}
      handleCheckoutError={handleCheckoutError}
      handleCheckoutLoading={handleCheckoutLoading}
      handleAttatchPaymentMethod={handleAttatchPaymentMethod}
    />
  );
};

export default DetailsModule;
